import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Form, Input, Divider, Typography, Breadcrumb, Popconfirm, Skeleton, Space, Row, Col } from "antd";
import { LinkOutlined, SaveOutlined } from "@ant-design/icons";
import _ from "lodash";

import { CardButtons, CardHeader } from "../components/Card";
import { DateCellRenderer } from "../components/CellRenderer";
import { PageError } from "../components/PageError";
import { MyTabs } from "../components/Tabs";
import { InputSegmentConditions, lastPosition } from "../components/InputSegmentConditions";
import { AsyncSelect } from "../components/FormInput";
import { AsyncSelect as ActivityAsyncSelect, createActivityRef } from "../components/Activity";

import { DateFormats } from "../constants";
import { callN8n2, callAdminRpc } from "../helpers/helpers";
import { showFailureSaveMessage, showSuccessSaveMessage, showSuccessMessage, showFailureMessage } from "../helpers/notification";
import { useManyJsonRpc } from "../hooks/useManyJsonRpc";
import { useN8n2 } from "../hooks/useN8n2";
import { useQuery } from "../hooks/useQuery";
import { history } from "../history";
import { CardScreen } from "../jhtml";

const { Text, Link: AntdLink } = Typography;

function SegmentBaseForm({ form, data, onSubmit, setActivityRef, children }) {
  const [_conditions, setConditions] = useState(_.get(data, "conditions") || []);

  const query = useQuery();
  const orig_id = query.get("orig_id") || null;

  const onConditionChange = (conditions) => {
    const newValues = {
      conditions,
    };

    if (conditions.length > _conditions.length) {
      const seq = form.getFieldValue("conditions_sequence");

      newValues["conditions_sequence"] = seq ? `${seq} И ${lastPosition(conditions)}` : `${lastPosition(conditions)}`;
    }

    setConditions(conditions);

    form.setFieldsValue(newValues);
  };

  return (
    <Form
      form={form}
      initialValues={data}
      layout="vertical"
      onFinish={onSubmit}
      validateMessages={{
        required: "Поле обязательно к заполнению",
      }}
    >
      <Form.Item name="subject" rules={[{ required: true }]} style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>

      <Row>
        <Col span={10}>
          <Form.Item label="Название" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Входит в активность">
            <ActivityAsyncSelect id_segment={!orig_id ? data.id : null} onComplete={setActivityRef} />
          </Form.Item>

          <Form.Item label="Как часто пересчитывать?" name="update_rate" rules={[{ required: true }]}>
            <AsyncSelect
              resource="segment_rate?order=position.asc"
              title="desc"
              onChange={(value) => form.setFieldsValue({ update_rate: value })}
              autoFocus={false}
            />
          </Form.Item>

          <Form.Item
            label="Логика условий"
            name="conditions_sequence"
            rules={[
              { required: true },
              {
                pattern: /^(и|или|[\d\s()])+$/i,
                message: `Можно использовать только следующие символы: цифры, пробелы, (), “И” и “ИЛИ”`,
              },
              {
                validator: (rule, sequence) => {
                  try {
                    const conditions = form.getFieldValue("conditions") || [];
                    const positions = conditions.map((c) => c.position);
                    const isOK = positions.every((pos) => sequence.includes(pos));

                    return isOK ? Promise.resolve() : Promise.reject(new Error("Должно присутствовать каждое условие хотя бы 1 раз"));
                  } catch (e) {
                    console.error(e);
                    return Promise.reject("Произошла ошибка");
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col offset={2} span={12}>
          {children}
        </Col>
      </Row>

      <Divider />

      <Form.Item label="" name="conditions" rules={[{ required: true }]}>
        <InputSegmentConditions onChange={onConditionChange} />
      </Form.Item>
    </Form>
  );
}

export function SegmentCreate() {
  const query = useQuery();
  const orig_id = query.get("orig_id") || null;

  const [form] = Form.useForm();

  const defaultValues = {
    subject: query.get("subject") || null,
  };

  const [isLoaded, setIsLoaded] = useState(true);
  const [data, setData] = useState(defaultValues);
  const [error, setError] = useState(null);
  const [activityRef, setActivityRef] = useState(null);

  useState(() => {
    if (!orig_id) {
      return;
    }

    setIsLoaded(false);
    callN8n2("admin.segment.details.v1", { id: orig_id })
      .then(setData)
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [orig_id]);

  function onSubmit(submission) {
    callN8n2("admin.segment.create.v1", submission)
      .then(({ id }) => {
        createActivityRef(activityRef, { id_segment: id });
        history.push(`/segments/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  function submitForm() {
    form.submit();
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/segments">Сегменты</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Новый сегмент</Breadcrumb.Item>
        </Breadcrumb>

        <CardButtons>
          <Button type="primary" icon={<SaveOutlined />} disabled={!isLoaded} onClick={submitForm}>
            Сохранить
          </Button>
        </CardButtons>
      </CardHeader>

      <Divider />

      {isLoaded ? (
        <SegmentBaseForm form={form} data={{ ...data, update_rate: "00:10:00" }} onSubmit={onSubmit} setActivityRef={setActivityRef} />
      ) : (
        <Skeleton active />
      )}
    </>
  );
}

function SegmentEdit({ loading, error, data, countData, form }) {
  const [activityRef, setActivityRef] = useState(null);

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Skeleton active />;
  }

  function onSubmit(submission) {
    callN8n2("admin.segment.update.v1", { ...submission, id: data.id })
      .then((r) => {
        createActivityRef(activityRef, { id_segment: r.id });
      })
      .then(showSuccessSaveMessage)
      .catch(showFailureSaveMessage);
  }

  return (
    <SegmentBaseForm form={form} data={data} onSubmit={onSubmit}>
      <Space direction="vertical" size={20}>
        {data.subject === "id_user" ? (
          <Space>
            <Text strong>Найдено по сегменту:</Text>
            <Text strong>{_.get(countData, "count")}</Text>
            <AntdLink href={`/users?segment=${data.id}`} target="_blank">
              <Space>
                <>смотреть</>
                <LinkOutlined />
              </Space>
            </AntdLink>
          </Space>
        ) : (
          <Space>
            <Text strong>Кол-во элементов в сегменте.:</Text>
            <Text strong>{_.get(countData, "count")}</Text>
            <AntdLink href={`/segment_items?id_segment=${data.id}`} target="_blank">
              <Space>
                <>смотреть</>
                <LinkOutlined />
              </Space>
            </AntdLink>
          </Space>
        )}

        <Space direction="vertical">
          <Space>
            <Text>Объект:</Text>
            <Text>{data.subject_title}</Text>
          </Space>

          <Space>
            <Text>Создано:</Text>
            <DateCellRenderer value={data.created_at} format={DateFormats.withSeconds} suffix="," />
            <Text>{data.user_created}</Text>
          </Space>

          <Space>
            <Text>Изменено:</Text>
            <DateCellRenderer value={data.updated_at} format={DateFormats.withSeconds} suffix="," />
            <Text>{data.user_updated}</Text>
          </Space>
        </Space>
      </Space>
    </SegmentBaseForm>
  );
}

export function SegmentCard() {
  const { id, tab } = useParams();

  const [form] = Form.useForm();

  const { data, loading, error, refetch } = useManyJsonRpc(
    useN8n2("admin.segment.details.v1", { id }, [id]),
    useN8n2("admin.segment.count.v1", { id }, [id])
  );

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <PageError error={error} />;
  }

  const [details, countData] = data;
  const [refetchDetails] = refetch;

  function submitForm() {
    form.submit();
  }

  async function onDelete() {
    try {
      await callN8n2("admin.segment.delete.v1", { id });

      showSuccessMessage("Сегмент удалён");

      history.push(`/segments`);
    } catch (e) {
      showFailureMessage("Не удалось удалить сегмент");
    }
  }

  async function onCopy() {
    history.push(`/segments/create?orig_id=${id}`);
  }

  async function toggleActive() {
    const active = details.integration_stopped;

    try {
      await callN8n2("admin.segment_integration.activate.v1", { id_segment: id, active });
      window.location.reload();
      //showSuccessMessage(active ? "Сегмент включен" : "Сегмент выключен");
      //refetchDetails();
    } catch (e) {
      showFailureMessage(`Не удалось ${active ? "включить" : "выключить"} сегмент`);
    }
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/segments?subject=${details.subject}`}>Сегменты</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{details.title}</Breadcrumb.Item>
        </Breadcrumb>

        <CardButtons>
          <Button type="primary" onClick={submitForm}>
            Сохранить
          </Button>

          {details.integrated && (
            <Popconfirm
              disabled={details.is_stopped}
              title={details.integration_stopped ? "Сегмент будет включен. Продолжить?" : "Сегмент будет выключен. Продолжить?"}
              onConfirm={toggleActive}
              okText="Да"
              cancelText="Отмена"
            >
              <Button disabled={details.is_stopped} type={details.is_stopped ? "primary" : "danger"} ghost={true}>
                {details.integration_stopped ? "Включить" : "Выключить"}
              </Button>
            </Popconfirm>
          )}

          <Button
            type="primary"
            ghost={true}
            onClick={() => callAdminRpc("recalc_segment_v1", { id: details.id }).then(() => window.location.reload())}
          >
            Пересчитать
          </Button>

          <Popconfirm title="Создать копию сегмента?" onConfirm={onCopy} okText="Да" cancelText="Нет">
            <Button type="primary" ghost={true}>
              Создать копию
            </Button>
          </Popconfirm>

          <Popconfirm title="Сегмент будет удалён. Продолжить?" onConfirm={onDelete} okText="Удалить" cancelText="Отмена">
            <Button type="danger" ghost={true}>
              Удалить
            </Button>
          </Popconfirm>
        </CardButtons>
      </CardHeader>

      <Divider />

      <MyTabs
        activeKey={tab}
        resolvePath={(key) => `/segments/${id}/${key}`}
        items={[
          {
            key: "edit",
            title: "Условия",
            children: <SegmentEdit form={form} data={details} countData={countData} error={error} />,
          },
          {
            key: "offers",
            title: "Интеграция",
            children: (
              <CardScreen
                dataFn="useN8n2"
                source="admin.screen.segment_integration.v1"
                idKey="IDSegment"
                vars={{ refetch: refetchDetails }}
              />
            ),
          },
        ]}
      />
    </>
  );
}
