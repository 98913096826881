// vendors

import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Table, Form, Input, Divider, Breadcrumb, Checkbox, Tag, Popconfirm, Skeleton, Space, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

// components

import { AsyncSelect2 } from "../components/FormInput";
import { PageError } from "../components/PageError";
import { CardButtons, CardHeader } from "../components/Card";
import { MyTabs } from "../components/Tabs";
import { AsyncSelect as ActivityAsyncSelect } from "../components/Activity";

// helpers

import { getSiteUrl, callN8n2 } from "../helpers/helpers";
import { showFailureMessage, showFailureSaveMessage, showSuccessSaveMessage } from "../helpers/notification";
import { handleSaveN8n, handleDeleteN8n } from "../helpers/request";
import { history } from "../history";
import { useN8n2 } from "../hooks/useN8n2";
import { render } from "../jhtml";

export function Forms() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    callN8n2("admin.form.list.v1")
      .then((r) => r.items)
      .then(setData)
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, []);

  function createNew() {
    history.push("/forms/create");
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title, record) => <Link to={`/forms/${record.id}`}>{title}</Link>,
    },
    {
      title: "Платформа",
      dataIndex: "platform",
      key: "platform",
      render: (platform) => platform || "-",
    },
    {
      title: "Статус",
      dataIndex: "active",
      key: "active",
      render: (active) => <Tag color={active ? "green" : "red"}>{active ? "Активна" : "Неактивна"}</Tag>,
    },
  ];

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Button type="primary" onClick={createNew}>
        + Добавить
      </Button>

      <Divider />

      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{
          pageSize: 50,
        }}
        loading={!isLoaded}
      />
    </>
  );
}

function FormBaseForm({ data, submitForm }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Название" name="title" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Платформа" name="platform" rules={[{ required: true }]}>
        <AsyncSelect2 jrpcMethod="admin.platform.list.v1" title="desc" style={{ width: 300 }} />
      </Form.Item>

      <Form.Item label="Входит в активность">
        <ActivityAsyncSelect id_form={data.id} />
      </Form.Item>

      <Form.Item name="active" valuePropName="checked">
        <Checkbox>Активна</Checkbox>
      </Form.Item>

      <Form.Item label='URL лендинга "спасибо"' name="url_thanks">
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function FormCreate() {
  function submitForm(submission) {
    callN8n2("admin.form.create.v1", submission)
      .then(({ id }) => {
        history.push(`/forms/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/forms">Формы</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Новая форма</Breadcrumb.Item>
      </Breadcrumb>

      <Divider />

      <FormBaseForm data={{}} submitForm={submitForm} />
    </>
  );
}

function FormOffers({ id, platform }) {
  const { loading, error, data } = useN8n2("admin.screen.form_offer_tab.v1", { id }, [id, platform]);

  if (loading) {
    return <Table loading={true} />;
  }

  if (error) {
    return <PageError />;
  }

  const deleteHandler = (e, succ) => {
    handleDeleteN8n("form_offer", {}, "v1", 2)(e, succ).catch((e) => console.error(e));
  };

  const saveHandler = (s, succ) => {
    handleSaveN8n("form_offer", { id_form: id }, null, "v1", 2)(s, succ).catch(showFailureSaveMessage);
  };

  return render(data, {
    vars: {
      platform,
      saveHandler,
      deleteHandler,
    },
  });
}

function FormCode({ id, uniq }) {
  const containerId = `payment_form_${uniq}`;
  const siteUrl = getSiteUrl();

  const code = `<!-- Это поместить в head -->
  <script src="${siteUrl}/static/gt-lib/gt.js?v=2"></script>
  
  <!-- Это поместить в body -->
  <div id="${containerId}"></div>
  
  <script>
  window.gymteam.createOfferForm(
    "${containerId}", 
    "${id}", 
    {${
      siteUrl !== "https://gymteam.ru"
        ? `
      origin: "${siteUrl}",`
        : ""
    }
      phone: "",                                  // установить телефон при инициализации формы
      email: "",                                  // установить почту при инициализации формы
      username: {
        enabled: true,                            // добавить сбор имени и фамилии в форме
        name: "",                                 // установить имя при инициализации формы
        surname: "",                              // установить фамилию при инициализации формы
      },
      button_text: "картой российского банка",    // текст кнопки оплаты
      prodamus: {
        enabled: true,                            // включение кнопки "Продамус"
        button_text: "картой иностранного банка", // текст кнопки
        color: "",                                // цвет кнопки
      },
      tinkoff_credit: {
        enabled: true,                            // включение кнопки рассрочки
        button_text: "Взять в рассрочку",         // текст кнопки рассрочки
        color: "#fbe04a",                         // цвет кнопки рассрочки
      },
      title: "Выберите вариант участия:",         // заголовок формы, если false или null, то заголовок будет скрыт
      utm: {
        utm_content: 'utm_campaign',              //  переопределение названия метки (берётся значение метки utm_campaign вместо utm_content)
      },
    }
  );
  </script>`;

  return (
    <Space direction="vertical" size={20} style={{ marginBottom: "20px" }}>
      <pre style={{ margin: 0 }}>{code}</pre>

      <CopyToClipboard
        text={code}
        onCopy={() => {
          notification.success({
            description: "Код скопирован",
          });
        }}
      >
        <Button type="primary">Копировать в буфер обмена</Button>
      </CopyToClipboard>
    </Space>
  );
}

export function FormEdit() {
  const { id, tab } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const loadData = useCallback(() => {
    setIsLoaded(false);
    callN8n2("admin.form.details.v1", { id })
      .then(setData)
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function submitForm(submission) {
    callN8n2("admin.form.update.v1", { ...submission, id })
      .then(() => {
        showSuccessSaveMessage();
        setData({ ...data, platform: submission.platform });
      })
      .catch((e) => {
        showFailureMessage(e.message);
      });
  }

  async function onDelete() {
    try {
      await callN8n2("admin.form.delete.v1", { id });
      notification.success({
        description: "Форма удалена",
      });
      history.push("/forms");
    } catch (e) {
      notification.error({
        description: "Не удалось удалить форму",
      });
    }
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/forms">Формы</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
        </Breadcrumb>

        <CardButtons>
          <Popconfirm
            disabled={!isLoaded}
            title={"Форма будет удалена. Продолжить?"}
            onConfirm={onDelete}
            okText="Удалить"
            cancelText="Отмена"
          >
            <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={!isLoaded}>
              Удалить
            </Button>
          </Popconfirm>
        </CardButtons>
      </CardHeader>

      <Divider />

      <MyTabs
        activeKey={tab}
        resolvePath={(key) => `/forms/${id}/${key}`}
        items={[
          {
            key: "base",
            title: "Основное",
            children: isLoaded ? <FormBaseForm data={data} submitForm={submitForm} /> : <Skeleton active />,
          },
          { key: "offers", title: "Предложения", children: <FormOffers id={id} platform={data.platform} /> },
          { key: "code", title: "Код для вставки", children: <FormCode id={id} uniq={new Date().getTime()} /> },
        ]}
      />
    </>
  );
}
